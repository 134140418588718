body {
  margin: 0;
  font-family: PingFang SC, Microsoft Yahei, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin: 0;
}

@media screen and (max-width: 767px) {
  .ef-kids-show-pc {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .ef-kids-show-mobile {
    display: none !important;
  }
}

.ReactModal__Overlay {
  z-index: 10000;
  background-color: rgb(77 77 77 / 75%) !important;
}

.ReactModal__Content {
  overflow: visible !important;
  padding: 0 !important;
  top: 50% !important;
  left: 50% !important;
  width: fit-content;
  height: fit-content;
  transform: translate(-50%, -50%);
}
.ReactModal__Body--open {
  overflow: hidden;
}
